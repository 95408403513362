import { openWidget } from 'components';
import { urlManager } from './urlManager';
import { IHandleClick } from 'data-layer/types/IHandleClick';
import { Gender, Sex, IVisit, IAppointmentStart } from 'data-layer/types';
import { GetUserInfo_getUserInfo } from 'data-layer/queries/__graphql__/GetUserInfo';
import { GetClientData_findOrCreateClient } from 'data-layer/queries/__graphql__/GetClientData';
import { IUserCombinedInfo } from 'data-layer/types/IUserCombinedInfo';
import {
  GetAppointment_getAppointment,
  GetAppointment_getAppointment_appointment,
} from 'data-layer/queries/__graphql__/GetAppointment';
import renderHTML from 'react-render-html';
import moment from 'moment';
import 'moment-timezone';
import { getPhoneString } from './phoneUtils';
import _ from 'lodash-es';
import { initJsonRpcMedMeAPI } from 'corev2-ts-sdk';
import { config, constants, IClientContext } from 'utils';

export const MIN_PASS_LENGTH = 8;

export const getKey = ({
  id,
  extraId,
  siteId,
}: {
  id: string;
  extraId?: string | null;
  siteId?: string | null;
}): string => {
  return siteId || extraId || id;
};

export const getUniqKey = ({
  id,
  extraId,
  siteId,
}: {
  id: string;
  extraId?: string | null;
  siteId?: string | null;
}): string => {
  if (siteId) {
    return `${siteId}_${id}`;
  }
  if (extraId) {
    return `${extraId}_${id}`;
  }
  return id;
};

export const handleOpenWidgetClick = ({ type, key }: IHandleClick): void => {
  const networkId = urlManager.getNetworkId();
  switch (type) {
    case 'location':
      if (key) {
        openWidget({ networkId, business: key });
      }
      break;
    case 'resource':
      if (key) {
        openWidget({ resourceId: key });
      }
      break;
    case 'taxonomy':
      if (key) {
        openWidget({ taxonomy: key });
      }
      break;
    case 'locations':
      openWidget({ networkId, screen: 'location' });
      break;
    case 'resources':
      openWidget({ networkId, screen: 'resource' });
      break;
    case 'taxonomies':
      openWidget({ networkId, screen: 'category' });
      break;

    default:
  }
};

export const getUserCombineInfo = (
  user: GetUserInfo_getUserInfo,
  client: GetClientData_findOrCreateClient,
): IUserCombinedInfo => {
  const userInfo = { ...user };
  if (!userInfo.email[0] && client.email[0]) {
    userInfo.email = client.email;
  }
  if (!userInfo.name || !userInfo.surname || userInfo.name === ' ' || userInfo.surname === ' ') {
    if (client.name !== ' ' && client.surname !== ' ') {
      userInfo.name = client.name;
      userInfo.surname = client.surname;
      userInfo.middleName = client.middleName;
    }
  }
  if (!userInfo.middleName && userInfo.name.split(' ').length > 1) {
    const nameParts = userInfo.name.split(' ');
    userInfo.name = nameParts.shift() || '';
    userInfo.middleName = nameParts.join(' ');
  }
  if (!userInfo.passportId && client.passportId) {
    userInfo.passportId = client.passportId;
  }

  // if we have gender in Client but not in profile, we should port it
  if (
    userInfo?.gender &&
    ![Gender.female, Gender.male].includes(userInfo.gender) &&
    client?.sex &&
    [Sex.FEMALE, Sex.MALE].includes(client.sex)
  ) {
    if (client.sex === Sex.FEMALE) {
      userInfo.gender = Gender.female;
    } else {
      userInfo.gender = Gender.male;
    }
  }
  return userInfo;
};

export const getVisit = (appointmentData: GetAppointment_getAppointment): IVisit => {
  const { appointment, resource, taxonomy, id, client, businessID } = appointmentData;
  const { start } = appointment;
  const { phone } = client;
  return {
    id,
    duration: appointment.duration,
    data: {
      id,
      start,
      client: {
        ...client,
        phone: getPhoneString(phone[0]),
      },
    },
    startTime: start,
    location: {
      key: businessID,
      businessId: businessID,
    },
    service: {
      id: taxonomy.id,
      key: taxonomy.id,
      businessId: businessID,
      mappedData: [
        {
          id: taxonomy.id,
          key: taxonomy.id,
          businessId: businessID,
        },
      ],
    },
    resource: {
      id: resource.id,
      key: resource.id,
      businessId: businessID,
      taxonomies: [taxonomy.id],
      mappedData: [
        {
          taxonomies: [taxonomy.id],
          id: resource.id,
          key: resource.id,
          businessId: businessID,
        },
      ],
    },
    clientShortId: client.shortId,
    key: id,
    isActive: true,
  };
};

export const getMultilineTranslation = (translation: string): string => {
  const lineBreakRegex = /\r?\n/g;
  const paragraphs = translation.split(lineBreakRegex);
  const resultStr = paragraphs.map((line) => `<p>${line}</p>`).join('');
  return renderHTML(resultStr);
};

export const dateFormatFunc = ({ format = 'D MMMM  YYYY HH:mm' }: { format: string }) => {
  return (d: Date): string => {
    return moment(d).calendar(null, { sameElse: format });
  };
};

export const testPass = (str: string, minLength = MIN_PASS_LENGTH): boolean => {
  const { length } = str;
  if (length < minLength) return true;
  const hasUpperCase = /[A-Z]/.test(str);
  const hasLowerCase = /[a-z]/.test(str);
  const hasDigit = /\d/.test(str);
  for (let i = 0; i < length - 2; i += 1) {
    const isSequentialNum = +str[i + 1] === +str[i] + 1 && +str[i + 2] === +str[i] + 2;
    const isSequentialChar =
      String.fromCharCode(str.charCodeAt(i) + 1) === str[+i + 1] &&
      String.fromCharCode(str.charCodeAt(i) + 2) === str[+i + 2];
    if (isSequentialNum || isSequentialChar) return true;
  }
  return !(hasUpperCase && hasLowerCase && hasDigit);
};

export const utcTimeLikeLocal = ({
  date,
  timezone,
}: {
  date: string;
  timezone: string;
}): string => {
  const time = moment(date).utc().format('YYYY-MM-DDTHH:mm:ss.000');
  if (!timezone) {
    return moment(time).toISOString();
  }
  const utc = moment.tz(time, timezone);
  return utc.toISOString();
};

export const getUTCDateForCompare = (date: string, timezone: string): string => {
  const startDate = _.clone(date);
  return utcTimeLikeLocal({ date: startDate, timezone });
};

export const getAppintmentStart = (
  { start, duration }: GetAppointment_getAppointment_appointment,
  timezone: string,
): IAppointmentStart => {
  const startLocal = getUTCDateForCompare(start, timezone);
  const preStartLocal = moment(startLocal).subtract(30, 'minutes');
  const endLocal = moment(startLocal).add(duration, 'minutes');
  return {
    appInPast: moment(startLocal).isBefore(moment.utc()),
    appSoonStart: moment.utc().isBetween(preStartLocal, startLocal),
    appStarted: moment.utc().isBetween(startLocal, endLocal),
  };
};

export const isClientCancellationForbidden = (start: string, clientContext: IClientContext) => {
  const { clientCancellationRestriction } =
    clientContext?.businessInfo?.backoffice_configuration || {};
  const { active, disableInHours } = clientCancellationRestriction || {};
  if (active && disableInHours) {
    const startLocal = moment(getUTCDateForCompare(start, clientContext.timezone));
    return startLocal.diff(moment.utc(), 'hours') <= disableInHours;
  }
  return false;
};

export const initMedMeSDK = (): void => {
  if (constants.devConfig) {
    initJsonRpcMedMeAPI({
      CORE_API_ENDPOINT: 'https://apiv2.dev.gbooking.ru/rpc',
      CRAC_SLOTS_API_ENDPOINT: 'https://cracslots.dev.gbooking.ru/rpc',
      CRAC_API_ENDPOINT: 'https://crac.dev.gbooking.ru/rpc',
      CRAC3_API_ENDPOINT: 'https://crac.dev.gbooking.ru/rpc',
      OAUTH_OTP_SEND: 'https://oauth.dev.gbooking.ru/sms/code',
      OAUTH_OTP_VERIFY: 'https://oauth.dev.gbooking.ru/sms/verify',
      OAUTH_OTP_WEBLOGIN: 'https://oauth.dev.gbooking.ru/web_login',

      JSONRPC_REQUEST_DEBUG: true,
      OTP_REQUEST_DEBUG: true,
    });
  } else if (constants.localConfig) {
    initJsonRpcMedMeAPI({
      CORE_API_ENDPOINT: 'https://apiv2.dev.gbooking.ru/rpc',
      CRAC_SLOTS_API_ENDPOINT: 'https://cracslots.dev.gbooking.ru/rpc',
      CRAC_API_ENDPOINT: 'https://crac.dev.gbooking.ru/rpc',
      CRAC3_API_ENDPOINT: 'https://crac.dev.gbooking.ru/rpc',
      OAUTH_OTP_SEND: 'https://oauth.dev.gbooking.ru/sms/code',
      OAUTH_OTP_VERIFY: 'https://oauth.dev.gbooking.ru/sms/verify',
      OAUTH_OTP_WEBLOGIN: 'https://oauth.dev.gbooking.ru/web_login',

      JSONRPC_REQUEST_DEBUG: true,
      OTP_REQUEST_DEBUG: true,
    });
  } else {
    initJsonRpcMedMeAPI();
  }
};

export const getAvatar = (url?: string): string => {
  if (url) {
    return `${config.REACT_APP_CDN_USER_IMG}${url}`;
  }
  return '';
};
