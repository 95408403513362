/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Resolvers, gql } from 'apollo-boost';
import Axios from 'axios';

import {
  AppointmentOptionsBusiness as AppointmentOptionsBusinessData,
  AppointmentOptionsBusinessVariables,
} from './__graphql__/AppointmentOptionsBusiness';

import * as api from './api';
import { Client } from './createApolloClient';
import * as mappers from './mappers';
import { IChangePass, Gender, IUpdateUserInfo, GlobalSearchType } from './types';
import { GBookingCoreV2, MedMeAPI } from 'corev2-ts-sdk';
import { medMeServices } from 'data-layer/helpers';
import { GET_NETWORK_BRANCH_LIST } from './queries';
import {
  getNetworkBranchList,
  getNetworkBranchListVariables,
} from './queries/__graphql__/getNetworkBranchList';
import { GetUserInfo, GetUserInfoVariables } from './queries/__graphql__/GetUserInfo';
import { GET_USER_INFO } from './queries/getUser';
import moment from 'moment';
import { config, getPhone, urlManager, constants, defaultTheme } from 'utils';
import { EHR } from 'medme-ehr-js-sdk';
import {
  fakeEhrApp,
  fakeEhrDiagnostic,
  getAppointmentResult,
  parseDiagnostic,
  parseDiagnostics,
  parseResponce,
} from './helpers/parseEHR';
import { removePhotoAPI, uploadPhotoAPI } from './api';
import { AppointmentMessage } from 'medme-ehr-js-sdk/dist/es5/messages/AppointmentMessage';
import { IDiagnosticReportMessage } from 'medme-ehr-js-sdk/dist/es5/interfaces';
import { AppointmentResultMessage } from 'medme-ehr-js-sdk/dist/es5/messages/AppointmentResultMessage';

type Context = {
  client: Client;
};

const saveUserData = ({
  data,
  client,
}: {
  data: {
    token: string;
    expires: string;
    user: string;
    askNewPass: boolean;
    smsCode?: string;
  };
  client: Client;
}) => {
  client.writeData({
    data: {
      token: data.token,
      user: data.user,
      expires: data.expires,
      isLoggedIn: true,
      askNewPass: data.askNewPass,
      showProfileScreen: data.askNewPass,
      applyAgreementStatus: data.askNewPass,
      smsCode: data.smsCode || '',
    },
  });
};

export const getApiUrl = (): string => {
  const GBOOKING_COREV2_RPC_URL = config.REACT_APP_GBOOKING_COREV2_RPC_URL;

  if (!GBOOKING_COREV2_RPC_URL) {
    throw new Error('Expected GBOOKING_COREV2_RPC_URL to be set');
  }

  return GBOOKING_COREV2_RPC_URL;
};

export const resolvers: Resolvers = {
  Query: {
    business: async (_root, { id }: { id: string }) => {
      const GBOOKING_COREV2_RPC_URL = getApiUrl();

      const profileRes = await api.fetchBusiness({
        GBOOKING_COREV2_RPC_URL,
        businessId: id,
      });

      const { business } = profileRes;

      return {
        ...business,
        resources: business.resources.map((resource) => ({
          ...resource,
          __typename: 'Resource',
        })),
        taxonomies: business.taxonomies.map((taxonomy) => ({
          ...taxonomy,
          __typename: 'Taxonomy',
        })),
        __typename: 'Business',
      };
    },
    getBusinessByID: async (
      _root,
      {
        id,
        contractID,
        contractExtraId,
      }: { id: string; contractID: string; contractExtraId: string },
    ) => {
      const businessData = await api.getBusinessByID(id, contractID, contractExtraId);
      const business = businessData?.business;
      const top_services = businessData?.top_services;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const ehrStatus = businessData?.business.integration_data?.ehr?.active || false;
      const lang = business?.general_info.language || 'ru-ru';
      const clientCabinetTranslates = business?.widget_configuration?.newWidgetTheme?.clientCabinetTranslates ? JSON.stringify(business?.widget_configuration?.newWidgetTheme?.clientCabinetTranslates) : '{}'
      const newWidgetTheme = Object.assign(defaultTheme, { clientCabinetTranslates });
      return {
        ...business,
        __typename: 'Business',
        id: business?.id,
        info: {
          id: business?.id || '',
          general_info: {
            ...business?.general_info,
            __typename: 'BusinessGeneralInfo',
            logo_url: business?.general_info.logo_url,
            name: business?.general_info.name,
            shortName: business?.general_info.shortName || '',
            description: business?.general_info.description || '',
            address: business?.general_info.address?.map((a) => {
              return {
                ...a,
                latitude: a.latitude || '',
                longitude: a.longitude || '',
                __typename: 'AddressSchema',
              };
            }),
            phone: business?.general_info.phone?.map((p) => {
              return {
                ...p,
                __typename: 'IncomingPhoneElement',
              };
            }),
          },
          widget_configuration: {
            ...business?.widget_configuration,
            newWidgetTheme: {
              ...newWidgetTheme,
              ...business?.widget_configuration?.newWidgetTheme?.patientPortal,
              __typename: 'NewWidgetTheme',
            },
            __typename: 'WidgetConfiguration',
          },
          backoffice_configuration: {
            ...business?.backoffice_configuration,
            enableExtendedPhone: business?.backoffice_configuration?.enableExtendedPhone || false,
            enablePhoneNationalMode:
              business?.backoffice_configuration?.enablePhoneNationalMode || false,
            clientCancellationRestriction: {
              active:
                business?.backoffice_configuration?.clientCancellationRestriction?.active || false,
              disableInHours:
                business?.backoffice_configuration?.clientCancellationRestriction?.disableInHours ||
                0,
              __typename: 'ClientCancellationRestriction',
            },
            __typename: 'BusinessConfiguration',
          },
          __typename: 'BusinessInfoClass',
        },
        integrationData: {
          ehr: {
            active: ehrStatus,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            protocol: businessData?.business.integration_data?.ehr?.protocol || '',
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            host: businessData?.business.integration_data?.ehr?.host || '',
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            port: businessData?.business.integration_data?.ehr?.port || '',
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            path: businessData?.business.integration_data?.ehr?.path || '',
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            availableEntities: businessData?.business.integration_data?.ehr?.availableEntities || [],
            __typename: 'IEHR',
          },
          __typename: 'NetworkIData',
        },
        resources: business?.resources.map((resource) => ({
          ...resource,
          extraId: resource.extraId || '',
          middleName: resource.middleName || '',
          siteId: resource.siteId || '',
          __typename: 'Resource',
        })),
        taxonomies: business?.taxonomies.map((taxonomy) => ({
          ...taxonomy,
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          name: taxonomy.alias ? `${taxonomy.alias[lang]}` : '',
          extraId: taxonomy.extraId || '',
          siteId: taxonomy.siteId || '',
          __typename: 'Taxonomy',
        })),
        top_services: {
          ...top_services,
          __typename: 'TopServices',
        },
      };
    },
    appointmentOptions: async (_root, { businessId }: { businessId: string }, ctx: Context) => {
      const { client } = ctx;

      const businessQueryResult = await client.query<
        AppointmentOptionsBusinessData,
        AppointmentOptionsBusinessVariables
      >({
        query: gql`
          query AppointmentOptionsBusiness($businessId: ID!) {
            business(id: $businessId) @client {
              id
              resources {
                id
                name
              }
              taxonomies {
                id
                taxonomyType
              }
            }
          }
        `,
        variables: { businessId },
      });

      const business = businessQueryResult.data?.business;

      if (!business) {
        return null;
      }

      const appointmentOptions = mappers.mapBusinessToAppointmentOptions(business);

      return {
        ...appointmentOptions,
        __typename: 'AppointmentOptions',
      };
    },
    findOrCreateClient: async (
      _root,
      { user, token, businessID }: { user: string; token: string; businessID: string },
    ) => {
      const clientData = await api.findOrCreateClient(
        { user, token } as GBookingCoreV2.Cred,
        businessID,
      );
      return {
        ...clientData.client,
        __typename: 'ClientData',
        email: (clientData.client.email || []).map((e) => `${e}`),
        middleName: clientData.client.middleName || '',
        birthday: clientData.client.birthday || '',
        passportId: clientData.client.passportId || '',
        icon_url: clientData.client.icon_url || '',
        phone: clientData.client.phone.map((p) => {
          return {
            ...p,
            __typename: 'IncomingPhoneElement',
          };
        }),
        contractAttachments:
          (clientData.client.contractAttachments || [])?.map((p) => {
            return {
              ...p,
              attachmentNumber: p.attachmentNumber || '',
              clientExtraId: '',
              dateBegin: p.dateBegin ? moment(p.dateBegin).format('DD.MM.YYYY') : '',
              dateEnd: p.dateBegin ? moment(p.dateEnd).format('DD.MM.YYYY') : '',
              __typename: 'ContractAttachmentInfo',
            };
          }) || [],
      };
    },
    getUserInfo: async (_root, { user, token }: { user: string; token: string }) => {
      const GBOOKING_COREV2_RPC_URL = getApiUrl();
      const userInfo = await api.getUserInfo(
        { user, token } as GBookingCoreV2.Cred,
        GBOOKING_COREV2_RPC_URL,
      );

      const gender = userInfo.gender === Gender.not_specified ? 'not_specified' : userInfo.gender;
      return {
        __typename: 'UserInfo',
        email: (userInfo.profile.email || []).map((e) => `${e}`),
        middleName: userInfo.profile.middle_name || '',
        id: userInfo.profile.id,
        name: userInfo.profile.name || '',
        surname: userInfo.profile.surname || '',
        birthday: userInfo.birthday
          ? moment.utc(userInfo.birthday).format(constants.DATE_FORMAT)
          : '',
        gender: gender || 'not_specified',
        passportId: userInfo.passportId || '',
        avatar: userInfo.avatar || '',
        phone: userInfo.profile.phone.map((p) => {
          return {
            ...p,
            __typename: 'IncomingPhoneElement',
          };
        }),
        medCardId: null,
        contractAttachments:
          (userInfo.profile.contractAttachments || [])?.map((p) => {
            return {
              ...p,
              attachmentNumber: p.attachmentNumber || '',
              clientExtraId: p.clientExtraId || '',
              dateBegin: p.dateBegin ? moment(p.dateBegin).format('DD.MM.YYYY') : '',
              dateEnd: p.dateBegin ? moment(p.dateEnd).format('DD.MM.YYYY') : '',
              __typename: '',
            };
          }) || [],
      };
    },
    getNetworkBusinessList: async (
      _root,
      { id, contractExtraId }: { id: string; contractExtraId: string },
      ctx: Context,
    ) => {
      const { client } = ctx;

      const networkData = await api.getNetworkBusinessList(+id, contractExtraId);
      const anyBusinessID = networkData?.[0]?.businessID;
      if (anyBusinessID) {
        client.writeData({ data: { anyBusinessID } });
      }
      const businesses = (networkData || []).map((b) => {
        return {
          ...b,
          businessID: `${b.businessID}`,
          info: {
            ...b.info,
            id: `${b.businessID}`,
            general_info: {
              ...b.info?.general_info,
              __typename: 'BusinessGeneralInfo',
              logo_url: b.info?.general_info.logo_url,
              name: b.info?.general_info.name,
              shortName: b.info?.general_info.shortName || '',
              address: b.info?.general_info.address?.map((a) => {
                return {
                  ...a,
                  latitude: a.latitude || '',
                  longitude: a.longitude || '',
                  __typename: 'AddressSchema',
                };
              }),
              description: b.info?.general_info.description || '',
              phone: b.info?.general_info.phone?.map((p) => {
                return {
                  ...p,
                  __typename: 'IncomingPhoneElement',
                };
              }),
            },
            backoffice_configuration: {
              ...b.info?.backoffice_configuration,
              clientCancellationRestriction: {
                active:
                  b.info?.backoffice_configuration?.clientCancellationRestriction?.active || false,
                disableInHours:
                  b.info?.backoffice_configuration?.clientCancellationRestriction?.disableInHours ||
                  0,
                __typename: 'ClientCancellationRestriction',
              },
              enableExtendedPhone: b.info?.backoffice_configuration?.enableExtendedPhone || false,
              enablePhoneNationalMode:
                b.info?.backoffice_configuration?.enablePhoneNationalMode || false,
              __typename: 'BusinessConfiguration',
            },
            widget_configuration: {
              ...b.info?.widget_configuration,
              __typename: 'WidgetConfiguration',
            },
            __typename: 'BusinessInfoClass',
          },
          __typename: 'BusinessRefInNetwork',
        };
      });

      return {
        businesses,
        __typename: 'NetworkBusinessList',
      };
    },
    getNetwork: async (
      _,
      {
        id,
        contractID,
        contractExtraId,
      }: { id: string; contractID: string; contractExtraId: string },
    ) => {
      const networkData: any = await api.getNetwork(
        +id,
        contractID,
        contractExtraId,
      );
      const clientCabinetTranslates = networkData.result?.businessConfiguration?.newWidgetTheme?.clientCabinetTranslates ? JSON.stringify(networkData.result?.businessConfiguration?.newWidgetTheme?.clientCabinetTranslates) : '{}'
      const newWidgetTheme = Object.assign(defaultTheme, { clientCabinetTranslates });
      const ehrStatus = networkData?.result?.integrationData?.ehr?.active || false;
      return {
        networkName: networkData?.result?.networkName || '',
        integrationData: {
          ehr: {
            active: ehrStatus,
            protocol: networkData?.result?.integrationData?.ehr?.protocol || '',
            host: networkData?.result?.integrationData?.ehr?.host || '',
            port: networkData?.result?.integrationData?.ehr?.port || '',
            path: networkData?.result?.integrationData?.ehr?.path || '',
            availableEntities: networkData.result?.integrationData?.ehr?.availableEntities || [],
            __typename: 'IEHR',
          },
          __typename: 'NetworkIData',
        },
        businessConfiguration: {
          newWidgetTheme: {
            ...newWidgetTheme,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            ...networkData.result?.businessConfiguration?.newWidgetTheme?.patientPortal,
            __typename: 'NewWidgetTheme',
          },
          __typename: 'NetworkBusinessConfiguration',
        },
        __typename: 'Network',
      };
    },
    getAppointmentHistory: async (
      _root,
      {
        clientID,
        user,
        token,
        businessID,
        networkID,
        contractID,
        contractExtraId,
      }: {
        clientID: string;
        user: string;
        token: string;
        businessID?: string;
        networkID?: string;
        contractID?: string;
        contractExtraId?: string;
      },
    ) => {
      let appData = await api.getAppointmentHistory(
        clientID,
        {
          user,
          token,
        } as GBookingCoreV2.Cred,
        businessID,
        networkID,
        contractID,
        contractExtraId,
      );

      appData = appData.map((app) => {
        return {
          ...app,
          id: app.appointment.id,
          businessID: app.business.id,
          __typename: 'Appointment',
          resource: {
            ...app.resource,
            middleName: app.resource.middleName || '',
            siteId: app.resource.siteId || '',
            degree: app.resource.degree || '',
            experience: app.resource.experience || '',
            icon_url: app.resource.icon_url || '',
            description: app.resource.description || '',
            profession: app.resource.profession || '',
            __typename: 'AppointmentResource',
          },
          taxonomy: {
            ...app.taxonomy,
            siteId: app.resource.siteId || '',
            confirmationAlert: app.taxonomy.confirmationAlert || '',
            extraDescription: app.taxonomy.extraDescription || '',
            __typename: 'AppointmentTaxonomy',
          },
          appointment: {
            ...app.appointment,
            __typename: 'AppointmentInfo',
            price: {
              ...app.appointment.price,
              __typename: 'Price',
            },
          },
          client: {
            ...app.client,
            phone: (app.client.phone || []).map((p) => {
              return {
                ...p,
                __typename: 'IncomingPhoneElement',
              };
            }),
            shortId: app.client.shortId,
            __typename: 'AppointmentClient',
          },
          telemedData: {
            ...app.telemedData,
            joinUrl: app.telemedData?.joinUrl || '',
            shortJoinUrl: app.telemedData?.shortJoinUrl || '',
            __typename: 'AppointmentTelemed',
          },
          extraFields: [
            ...app.extraFields.map((field) => {
              return {
                ...field,
                value: field.value || '', // hack to graphql
                name: field.name ? field.name : null,
                __typename: 'ExtraField',
              };
            }),
          ]
        };
      });

      return {
        __typename: 'AppointmentList',
        appointments: appData || [],
      };
    },
    searchInBusiness: async (
      _root,
      {
        search,
        businessID,
        networkID,
        type,
      }: {
        search: string;
        businessID?: string;
        networkID?: string;
        type?: GlobalSearchType;
      },
    ) => {
      const GBOOKING_COREV2_RPC_URL = getApiUrl();

      const data = await api.searchInBusiness({
        search,
        type,
        networkId: networkID,
        businessId: businessID,
        GBOOKING_COREV2_RPC_URL,
      });

      return {
        ...data,
        __typename: 'SearchInBusinessResult',
        resources: (data.resources || []).map((r) => {
          return {
            ...r,
            middleName: r.middleName || '',
            profession: r.profession || '',
            description: r.description || '',
            image: r.image || '',
            extraId: r.extraId || '',
            siteId: r.siteId || '',
            __typename: 'Resource',
          };
        }),
        taxonomies: (data.taxonomies || []).map((t) => {
          return {
            ...t,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            name: (t.alias && t.alias['ru-ru']) || '',
            extraId: t.extraId || '',
            siteId: t.siteId || '',
            __typename: 'Taxonomy',
          };
        }),
        locations: (data.locations || []).map((l) => {
          return {
            ...l,
            internalID: l.internalID || '',
            general_info: {
              ...l.general_info,
              address: l.general_info.address?.map((a) => {
                return {
                  ...a,
                  latitude: a.latitude || '',
                  longitude: a.longitude || '',
                  __typename: 'AddressSchema',
                };
              }),
              __typename: 'BusinessGeneralInfo',
            },
            __typename: 'LocationSearchResult',
          };
        }),
      };
    },
    getPopularTaxonomies: async (
      _root,
      {
        networkID,
        contractExtraId,
      }: {
        networkID: string;
        contractExtraId: string;
      },
      ctx: Context,
    ) => {
      const GBOOKING_COREV2_RPC_URL = getApiUrl();
      const { client } = ctx;

      const data = await api.getPopularTaxonomies({
        networkId: networkID,
        GBOOKING_COREV2_RPC_URL,
      });

      const queryResult = await client.query<getNetworkBranchList, getNetworkBranchListVariables>({
        query: GET_NETWORK_BRANCH_LIST,
        variables: { networkID, contractExtraId },
        fetchPolicy: 'network-only',
      });
      return {
        ...data,
        __typename: 'PopularTaxonomiesResult',
        resources: (data.resources || []).map((r) => {
          return {
            ...r,
            extraId: r.extraId || '',
            siteId: r.siteId || '',
            __typename: 'Resource',
          };
        }),
        taxonomies: (data.taxonomies || []).map((t) => {
          return {
            ...t,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            name: (t.alias && t.alias['ru-ru']) || '',
            extraId: t.extraId || '',
            siteId: t.siteId || '',
            __typename: 'Taxonomy',
          };
        }),
        locations: (queryResult.data.getNetworkBusinessList?.businesses || []).map((l) => {
          return {
            id: l.info.id,
            general_info: l.info.general_info,
            __typename: 'BusinessInfoClass',
          };
        }),
      };
    },
    getPatientAppointments: async (
      _root,
      {
        businessID,
        clientId,
        patientId,
        user,
        token,
        offset = 0,
        size = 6,
        ehrEndPoint,
      }: {
        businessID: string;
        clientId: string;
        patientId: string;
        user: string;
        token: string;
        offset: number;
        size: number;
        ehrEndPoint: string;
      },
      ctx: Context,
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { client } = ctx;
      const medmeServices = medMeServices({
        businessId: businessID,
        clientId,
        user,
        token,
        ehrEndPoint,
      });
      const clientVisits: AppointmentMessage[] = [];
      if (offset > 0) {
        // todo: page loading from ehr
      }

      if (config.MOCK_EHR) {
        try {
          const req = await fetch('/mock/get_patient_appointments.json');
          const fake: fakeEhrApp = await req.json();
          const { appointments } = fake.result;
          const appData = parseResponce(appointments, clientVisits);
          return {
            __typename: 'PatientAppointments',
            appointments: appData || [],
          };
        } catch (error) {
          return [];
        }
      }

      return new Promise((resolve, reject) => {
        medmeServices.appointmentService.getPatientAppointments(
          patientId,
          size,
          offset,
          (err, appointments) => {
            if (err) {
              if (err instanceof EHR.Services.ConnectionError) {
                // eslint-disable-next-line prefer-promise-reject-errors
                reject({ message: 'screens.ehr.ehrConnectionFailed' });
              }

              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ message: 'screens.ehr.ehrError' });
            }
            const appData = parseResponce(appointments, clientVisits);
            resolve({
              __typename: 'PatientAppointments',
              appointments: appData || [],
            });
          },
          true
        );
      });
    },
    getPatientResultsCount: async (
      _root,
      {
        businessID,
        clientId,
        patientId,
        user,
        token,
        ehrEndPoint,
      }: {
        businessID: string;
        clientId: string;
        patientId: string;
        user: string;
        token: string;
        ehrEndPoint: string;
      },
      ctx: Context,
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { client } = ctx;
      const medmeServices = medMeServices({
        businessId: businessID,
        clientId,
        user,
        token,
        ehrEndPoint,
      });
      const clientVisits: AppointmentMessage[] = [];

      if (config.MOCK_EHR) {
        try {
          const req = await fetch('/mock/get_patient_appointments.json');
          const fake: fakeEhrApp = await req.json();
          const { appointments } = fake.result;
          const appData = parseResponce(appointments, clientVisits);
          return {
            __typename: 'PatientAppointmentsCount',
            count: appData.length,
            support: false
          };
        } catch (error) {
          return [];
        }
      }

      return new Promise((resolve, reject) => {
        medmeServices.appointmentResultService.getPatientAppointmentResultsCount(
          patientId,
          (err, count, support) => {
            if (err) {
              if (err instanceof EHR.Services.ConnectionError) {
                // eslint-disable-next-line prefer-promise-reject-errors
                reject({ message: 'screens.ehr.ehrConnectionFailed' });
              }

              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ message: 'screens.ehr.ehrError' });
            }

            resolve({
              __typename: 'PatientAppointmentsCount',
              count,
              support
            });
          },
        );
      });
    },
    getPatientAppointmentResultsCount: async (
      _root,
      {
        businessID,
        clientId,
        patientId,
        user,
        token,
        offset = 0,
        ehrEndPoint,
      }: {
        businessID: string;
        clientId: string;
        patientId: string;
        user: string;
        token: string;
        offset: number;
        ehrEndPoint: string;
      },
      ctx: Context,
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { client } = ctx;
      const medmeServices = medMeServices({
        businessId: businessID,
        clientId,
        user,
        token,
        ehrEndPoint,
      });
      const clientVisits: AppointmentMessage[] = [];
      if (offset > 0) {
        // todo: page loading from ehr
      }

      if (config.MOCK_EHR) {
        try {
          const req = await fetch('/mock/get_patient_appointments.json');
          const fake: fakeEhrApp = await req.json();
          const { appointments } = fake.result;
          const appData = parseResponce(appointments, clientVisits);
          return {
            __typename: 'PatientAppointmentsCount',
            count: appData.length,
            support: false
          };
        } catch (error) {
          return [];
        }
      }

      return new Promise((resolve, reject) => {
        medmeServices.appointmentService.getPatientAppointmentsCount(
          patientId,
          (err, count, support) => {
            if (err) {
              if (err instanceof EHR.Services.ConnectionError) {
                // eslint-disable-next-line prefer-promise-reject-errors
                reject({ message: 'screens.ehr.ehrConnectionFailed' });
              }

              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ message: 'screens.ehr.ehrError' });
            }

            resolve({
              __typename: 'PatientAppointmentsCount',
              count,
              support
            });
          },
        );
      });
    },
    getEHRDiagnosticsCount: async (
      _root,
      {
        businessID,
        clientId,
        patientId,
        user,
        token,
        ehrEndPoint,
      }: {
        businessID: string;
        clientId: string;
        patientId: string;
        user: string;
        token: string;
        ehrEndPoint: string;
      },
      ctx: Context,
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { client } = ctx;
      const medmeServices = medMeServices({
        businessId: businessID,
        clientId,
        user,
        token,
        ehrEndPoint,
      });
      const clientVisits: AppointmentMessage[] = [];

      if (config.MOCK_EHR) {
        try {
          const req = await fetch('/mock/get_patient_appointments.json');
          const fake: fakeEhrApp = await req.json();
          const { appointments } = fake.result;
          const appData = parseResponce(appointments, clientVisits);
          return {
            __typename: 'PatientAppointmentsCount',
            count: appData.length,
            support: false
          };
        } catch (error) {
          return [];
        }
      }

      return new Promise((resolve, reject) => {
        medmeServices.diagnosticReportService.getPatientDiagnosticReportsCount(
          patientId,
          (err, count, support) => {
            if (err) {
              if (err instanceof EHR.Services.ConnectionError) {
                // eslint-disable-next-line prefer-promise-reject-errors
                reject({ message: 'screens.ehr.ehrConnectionFailed' });
              }

              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ message: 'screens.ehr.ehrError' });
            }

            resolve({
              __typename: 'PatientAppointmentsCount',
              count,
              support
            });
          },
        );
      });
    },
    getPatientAppointmentResults: async (
      _root,
      {
        businessID,
        clientId,
        patientId,
        user,
        token,
        offset = 0,
        size = 6,
        ehrEndPoint,
      }: {
        businessID: string;
        clientId: string;
        patientId: string;
        user: string;
        token: string;
        offset: number;
        size: number;
        ehrEndPoint: string;
      },
      ctx: Context,
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { client } = ctx;
      const medmeServices = medMeServices({
        businessId: businessID,
        clientId,
        user,
        token,
        ehrEndPoint,
      });
      const clientVisits: AppointmentMessage[] = [];
      if (offset > 0) {
        // todo: page loading from ehr
      }

      if (config.MOCK_EHR) {
        try {
          const req = await fetch('/mock/get_patient_appointments.json');
          const fake: fakeEhrApp = await req.json();
          const { appointments } = fake.result;
          const appData = parseResponce(appointments, clientVisits);
          return {
            __typename: 'PatientAppointmentResults',
            appointmentResults: appData || [],
          };
        } catch (error) {
          return [];
        }
      }

      return new Promise((resolve, reject) => {
        medmeServices.appointmentResultService.getPatientAppointmentResults(
          // medmeServices.appointmentResultService.getPatientAppointmentResults(
          patientId,
          size,
          offset,
          (err, appointments) => {
            if (err) {
              if (err instanceof EHR.Services.ConnectionError) {
                // eslint-disable-next-line prefer-promise-reject-errors
                reject({ message: 'screens.ehr.ehrConnectionFailed' });
              }

              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ message: 'screens.ehr.ehrError' });
            }
            const appData = (appointments || []).map(getAppointmentResult);

            resolve({
              __typename: 'PatientAppointmentResults',
              appointmentResults: appData || [],
            });
          },
          true
        );
      });
    },
    getEHRAppointmentResult: async (
      _root,
      {
        businessID,
        clientId,
        visitId,
        user,
        token,
        ehrEndPoint,
      }: {
        businessID: string;
        clientId: string;
        visitId: string;
        user: string;
        token: string;
        ehrEndPoint: string;
      },
    ) => {
      const medmeServices = medMeServices({
        businessId: businessID,
        clientId,
        user,
        token,
        ehrEndPoint,
      });
      if (config.MOCK_EHR) {
        try {
          const req = await fetch('/mock/get_patient_appointment_results.json');
          const fake: fakeEhrApp = await req.json();
          const fakeRes = fake.result.appointments.find((app) => app.id === visitId);
          if (fakeRes !== undefined) {
            const res = (fakeRes as unknown) as AppointmentResultMessage;
            const appResult = getAppointmentResult(res);
            return {
              ...appResult,
              __typename: 'PatientAppointmentResult',
            };
          }
        } catch (error) {
          return [];
        }
      }

      return new Promise((resolve, reject) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        medmeServices.appointmentResultService.getAppointmentResultById(visitId, (err, res) => {
          if (res && res.id) {
            const appResult = getAppointmentResult(res);
            resolve({
              ...appResult,
              __typename: 'PatientAppointmentResult',
            });
          }
          if (err) {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({ message: 'screens.ehr.ehrError' });
          }
        });
      });
    },
    getEHRDiagnostics: async (
      _root,
      {
        businessID,
        clientId,
        patientId,
        user,
        token,
        offset = 0,
        size = 6,
        ehrEndPoint,
      }: {
        businessID: string;
        clientId: string;
        patientId: string;
        user: string;
        token: string;
        offset: number;
        size: number;
        ehrEndPoint: string;
      },
      ctx: Context,
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { client } = ctx;
      const medmeServices = medMeServices({
        businessId: businessID,
        clientId,
        user,
        token,
        ehrEndPoint,
      });

      if (config.MOCK_EHR) {
        try {
          const req = await fetch('/mock/get_patient_diagnostic_reports.json');
          const fake: fakeEhrDiagnostic = await req.json();
          const clientDiagnostics = fake.result.diagnosticReports;

          const appData = parseDiagnostics(clientDiagnostics);
          return {
            __typename: 'PatientDiagnostics',
            diagnostics: appData || [],
          };
        } catch (error) {
          return [];
        }
      }

      return new Promise((resolve, reject) => {
        medmeServices.diagnosticReportService.getPatientDiagnosticReports(
          patientId,
          size,
          offset,
          (err: any, reports: IDiagnosticReportMessage[]) => {
            if (err) {
              // ignore unknown error from api
              if (err?.code === -32603) {
                return resolve({
                  __typename: 'PatientDiagnostics',
                  diagnostics: [],
                });

              } else if (err instanceof EHR.Services.ConnectionError) {
                // eslint-disable-next-line prefer-promise-reject-errors
                return reject({ message: 'screens.ehr.ehrConnectionFailed' });
              }

              // eslint-disable-next-line prefer-promise-reject-errors
              return reject({ message: 'screens.ehr.ehrError' });
            }
            const clientDiagnostics: IDiagnosticReportMessage[] = reports || [];

            const appData = parseDiagnostics(clientDiagnostics);
            resolve({
              __typename: 'PatientDiagnostics',
              diagnostics: appData || [],
            });
          },
          true
        );
      });
    },
    getEHRDiagnosticResult: async (
      _root,
      {
        businessID,
        clientId,
        visitId,
        user,
        token,
        ehrEndPoint,
      }: {
        businessID: string;
        clientId: string;
        visitId: string;
        user: string;
        token: string;
        ehrEndPoint: string;
      },
    ) => {
      const medmeServices = medMeServices({
        businessId: businessID,
        clientId,
        user,
        token,
        ehrEndPoint,
      });
      if (config.MOCK_EHR) {
        try {
          const req = await fetch('/mock/get_patient_diagnostic_reports.json');
          const fake: fakeEhrDiagnostic = await req.json();
          const fakeRes = fake.result.diagnosticReports.find((app) => app.id === visitId);
          if (fakeRes !== undefined) {
            const res = (fakeRes as unknown) as IDiagnosticReportMessage;
            const appResult = parseDiagnostic(res);
            return {
              ...appResult,
              __typename: 'PatientAppointmentResult',
            };
          }
        } catch (error) {
          return [];
        }
      }

      return new Promise((resolve, reject) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        medmeServices.diagnosticReportService.getDiagnosticReportById(visitId, (err, res) => {
          if (res && res.id) {
            const appResult = parseDiagnostic(res);
            resolve({
              ...appResult,
              __typename: 'PatientAppointmentResult',
            });
          }
          if (err) {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({ message: 'screens.ehr.ehrError' });
          }
        });
      });
    },
    getCountryCode: async () => {
      return Axios.get('http://ip-api.com/json')
        .then((response) => ((response as unknown) as Response).json())
        .then((responseJson: { countryCode: string }) => {
          return responseJson.countryCode;
        })
        .catch(() => {
          return 'RU';
        });
    },

    uploadPhoto: async (
      _root,
      {
        fileToken,
        user,
        token,
      }: {
        fileToken: string;
        user: string;
        token: string;
      },
    ) => {
      if (!fileToken) {
        return false;
      }
      const GBOOKING_COREV2_RPC_URL = getApiUrl();
      const upload = await uploadPhotoAPI(fileToken, { user, token }, GBOOKING_COREV2_RPC_URL);
      return !!upload;
    },
    removePhoto: async (
      _root,
      {
        user,
        token,
      }: {
        user: string;
        token: string;
      },
    ) => {
      const GBOOKING_COREV2_RPC_URL = getApiUrl();
      const upload = await removePhotoAPI({ user, token }, GBOOKING_COREV2_RPC_URL);
      return !!upload;
    },
  },
  Mutation: {
    login: async (
      _,
      { phone, password, country }: { phone: string; password: string; country: string },
      ctx: Context,
    ) => {
      const { client } = ctx;
      const { country_code, area_code, number } = getPhone(phone, country);
      const formattedPhoneString = `${country_code}${area_code}${number}`;
      const loginResult = await api.loginUser(formattedPhoneString, password);
      if (loginResult && loginResult.token) {
        saveUserData({
          client,
          data: {
            smsCode: password,
            ...loginResult,
          },
        });
        return {
          __typename: 'UserToken',
          ...loginResult,
        };
      }

      return Promise.reject(new Error(''));
    },
    requestSmsCode: async (
      _root,
      { phone, country }: { phone: string; country: string },
      ctx: Context,
    ) => {
      const { client } = ctx;
      let businessID: string = urlManager.getBusinessId() || '';
      const networkID = urlManager.getNetworkId();
      if (!businessID && networkID) {
        const networkQueryResult = await client.query<
          getNetworkBranchList,
          getNetworkBranchListVariables
        >({
          query: gql`
            query getNetworkBusinessID($networkID: ID!, $contractExtraId: String!) {
              getNetworkBusinessList(id: $networkID, contractExtraId: $contractExtraId) @client {
                businesses {
                  businessID
                }
              }
            }
          `,
          variables: {
            networkID,
            contractExtraId: urlManager.getContractExtraId(),
          },
          fetchPolicy: 'no-cache',
        });
        const businessList = networkQueryResult.data.getNetworkBusinessList?.businesses;
        if (!businessList || businessList.length === 0) {
          return null;
        }
        businessID = businessList[0]?.businessID || '';
      }
      const formattedPhone = getPhone(phone, country);
      const result = await api.requestSmsCode(formattedPhone, businessID);
      if (result && result.token) {
        return { token: result.token, __typename: 'SmsToken' };
      }

      return Promise.reject(new Error(result.error || 'bad phone'));
    },
    confirmSmsCode: async (
      _root,
      { code, token }: { code: string; token: string },
      ctx: Context,
    ) => {
      const { client } = ctx;
      const businessID = urlManager.getBusinessId();
      const data = {
        code,
        token,
        businessID,
      };
      const result = await api.confirmSmsCode(data);
      if (result && result.token) {
        const clientData = {
          smsCode: code,
          ...result,
        };
        saveUserData({ client, data: clientData });
        return { __typename: 'UserToken', ...clientData };
      }

      return Promise.reject(new Error(result.error || 'bad code'));
    },
    changePassword: async (_root, { id, password, currentPassword }: IChangePass, ctx: Context) => {
      const { client } = ctx;

      const changePass = await api.changePass({ id, password, currentPassword });
      if (changePass) {
        client.writeData({ data: { askNewPass: false } });
      }
      return changePass;
    },
    cancelAppointment: async (
      _,
      {
        appointmentID,
        shortId,
      }: {
        appointmentID: string;
        shortId: string;
      },
    ) => {
      return MedMeAPI.appointment.cancelAppointmentByClient({
        appointment: { id: appointmentID, shortId },
      });
    },
    updateUserInfo: async (
      _root,
      {
        name,
        middleName,
        surname,
        gender,
        birthday,
        user,
        token,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        medCardId,
        passportId,
      }: {
        name: string;
        middleName: string;
        surname: string;
        birthday: string;
        gender: Gender;
        user: string;
        token: string;
        medCardId?: string;
        passportId: string;
      },
      ctx: Context,
    ) => {
      const { client } = ctx;
      const updateUserInfo: IUpdateUserInfo = {
        name,
        middleName,
        surname,
        gender,
        birthday,
        passportId,
      };
      const cred: GBookingCoreV2.Cred = {
        user,
        token,
      };
      updateUserInfo.birthday = moment.utc(updateUserInfo.birthday, constants.DATE_FORMAT).toDate();
      const GBOOKING_COREV2_RPC_URL = getApiUrl();
      const updateUserInfoResult: boolean = await api.updateUserInfo(
        updateUserInfo,
        cred,
        GBOOKING_COREV2_RPC_URL,
      );

      if (updateUserInfoResult) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const networkQueryResult = await client.query<GetUserInfo, GetUserInfoVariables>({
          query: GET_USER_INFO,
          variables: {
            user,
            token,
          },
          fetchPolicy: 'network-only',
        });
        client.writeData({ data: { showProfileScreen: false } });
      }

      return updateUserInfoResult;
    },
    setPatientId: (_root, { id }: { id: string }, ctx: Context) => {
      const { client } = ctx;
      client.writeData({ data: { patientId: id } });

      return true;
    },
    skipUpdateUserInfo: (_root, _, ctx: Context) => {
      const { client } = ctx;
      client.writeData({ data: { showProfileScreen: false } });

      return true;
    },
    setPhoneCountry: (_root, { country }: { country: string }, ctx: Context) => {
      const { client } = ctx;
      client.writeData({ data: { phoneCountry: country } });

      return true;
    },
  },
};

/**
 * Upload file, return file token
 * @param file
 */
export const uploadFile = async (file: File): Promise<string> => {
  const formData = new FormData();
  formData.append('filename', file, file.name);
  const result = await Axios.post('upload_temp_file', formData, {
    baseURL: config.REACT_APP_GBOOKING_COREV2_RPC_URL.replace('/rpc', '/'),
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return (result?.data.token as string) || '';
};
