import React, { Fragment } from 'react';
import { Router } from '@reach/router';
import { WidgetModal, ClientContextProvider, ContentDirection } from '../components';
import { paths } from 'utils/routing';

import HomeScreen from './homeScreen';
import AppointmentsScreen from './appointmentsScreen';
import ProfileEditScreen from './profileEditScreen';
import ProfileInfoScreen from './profileInfoScreen';
import NewRecordScreen from './newRecordScreen';
import SearchScreen from './searchScreen';
import EhrScreen from './ehrScreen';
import EHRAppointmentResultScreen from './ehrAppointmentResultScreen';
import EHRDiagnosticResultScreen from './ehrDiagnosticResultScreen';

export default function Pages(): JSX.Element {
  return (
    <ClientContextProvider>
      <ContentDirection />
      <Router primary={false} component={Fragment}>
        <HomeScreen path={paths.homePath} />
        <NewRecordScreen path={paths.newRecordPath} />
        <ProfileEditScreen path={paths.profileEditPath} />
        <ProfileInfoScreen path={paths.profileInfoPath} />
        <AppointmentsScreen path={paths.appointmentPath(':appointmentID')} />
        <EHRAppointmentResultScreen path={paths.ehrAppointmentPath(':appointmentID')} />
        <EHRDiagnosticResultScreen path={paths.ehrDiagnosticPath(':appointmentID')} />
        <SearchScreen path={paths.searchPath} />
        <EhrScreen path={paths.ehrPath} />
      </Router>
      <WidgetModal />
    </ClientContextProvider>
  );
}
