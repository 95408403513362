import { gql } from 'apollo-boost';

export const GET_BUSINESS_INFO = gql`
  query GetBusinessInfo($ID: ID!) {
    getBusinessInfo(id: $ID) @client {
      general_info {
        logo_url
        name
        shortName
        address {
          country
          address
          latitude
          longitude
        }
        description
        phone {
          country_code
          area_code
          number
        }
        timezone
        showExtraFieldsInClientCabinet
      }
      backoffice_configuration {
        enableExtendedPhone
        enablePhoneNationalMode
        clientCancellationRestriction {
          active
          disableInHours
        }
      }
      widget_configuration {
        requireAgreement
        requireAgreementLink
        middleNameSupport
      }
    }
  }
`;
