import React, { useContext, useState, useRef } from 'react';
import styled from '@emotion/styled';

import PageContainer from './pageContainer';
import Heading from './Heading';
import AuthForm from './AuthForm';
import Button from './button';
import { iconType } from './Input';
import CodeInput from './CodeInput';
import { smallText, unit, contentCSS } from '../styles';
import * as RequestSmsCode from '../pages/__graphql__/RequestSmsCode';
import * as ConfirmSmsCode from '../pages/__graphql__/ConfirmSmsCode';
import { withTranslation, WithTranslation } from 'react-i18next';
import { checkPhone } from 'utils/phoneUtils';
import InputAlert from './InputAlert';
import { PhoneInputComponent as PhoneInput } from './PhoneInput';
import { GetBusinessInfo_getBusinessInfo } from 'data-layer/queries/__graphql__/GetBusinessInfo';
import { ClientContext } from '../utils/ClientContext';
import Logo from './Logo';
import { urlManager } from 'utils/urlManager';
import { useQuery } from '@apollo/react-hooks';
import { getNetworkBranchList, getNetworkBranchListVariables } from 'data-layer/queries/__graphql__/getNetworkBranchList';
import { GET_BUSINESS, GET_NETWORK_BRANCH_LIST } from 'data-layer/queries';
import { getBusiness, getBusinessVariables } from 'data-layer/queries/__graphql__/getBusiness';

export const SMS_CODE_LENGTH = 5;

interface LoginSmsFormProps {
  requestSmsCode: (a: { variables: RequestSmsCode.RequestSmsCodeVariables }) => void;
  confirmSmsCode: (a: { variables: ConfirmSmsCode.ConfirmSmsCodeVariables }) => void;
  switchAuth: () => void;
  phone: string;
  onPhoneChange: (phone: string, country: string) => void;
  smsToken: string;
  businessInfo: GetBusinessInfo_getBusinessInfo;
  country?: string;
  smsSent?: boolean;
  error?: boolean;
  errorRequestCode?: boolean;
  errorConfirmCode?: boolean;
}

const LoginSmsForm: React.FC<LoginSmsFormProps & WithTranslation> = ({
  requestSmsCode,
  confirmSmsCode,
  switchAuth,
  phone,
  onPhoneChange,
  smsToken,
  businessInfo,
  country,
  smsSent,
  error,
  errorRequestCode,
  errorConfirmCode,
  t,
}) => {
  const { theme } = useContext(ClientContext);
  const [phoneState, setPhoneState] = useState({
    phone,
    phoneValid: !phone.length || checkPhone(phone, country),
    errorVisible: !!error,
    country: country || '',
  });
  let codeInput = useRef<HTMLInputElement[] | null>(null);

  const focusCodeInput = () => {
    const { current } = codeInput;
    if (smsSent && current && current.length) {
      current[0].focus();
    }
  };

  const onChangePhone = (phone: string, phoneValid: boolean, countryCode?: string) => {
    const { country: countryState } = phoneState;
    // if (countryCode !== countryState) {
      onPhoneChange(phone, countryCode || '');
    // }
    setPhoneState({
      phone,
      phoneValid,
      errorVisible: false,
      country: countryCode || '',
    });
  };

  const onSubmit = () => {
    onPhoneChange(phoneState.phone, phoneState.country);
    return requestSmsCode({
      variables: {
        phone: phoneState.phone,
        country: phoneState.country,
      },
    });
  };

  const onChangeSmsCode = (smsCode: string) => {
    const { errorVisible } = phoneState;
    setPhoneState({ ...phoneState, errorVisible: false });
    codeInput?.current?.[smsCode.length]?.focus?.();
    codeInput?.current?.[smsCode.length]?.scrollIntoView?.();
    if (smsCode.length === SMS_CODE_LENGTH) {
      confirmSmsCode({
        variables: {
          token: smsToken,
          code: smsCode,
        },
      });
    }
  };

  const requestSmsCodeElement = () => {
    const { phoneValid } = phoneState;
    return (
      <>
        {renderPhoneInput()}
        {phoneValid && !smsSent ? (
          <>
            <Button theme={theme} onClick={onSubmit}>
              {t('components.auth.getSmsCode')}
            </Button>
          </>
        ) : (
          ''
        )}
      </>
    );
  };

  const confirmSmsCodeElement = () => {
    const { errorVisible } = phoneState;
    return (
      <>
        <StyledLabel>{t('components.auth.smsCode')}</StyledLabel>
        <CodeInputWrapper>
          <CodeInput
            length={SMS_CODE_LENGTH}
            onChange={onChangeSmsCode}
            icon={errorVisible ? 'error' : undefined}
            getRef={(newCodeInput) => {
              codeInput = newCodeInput;
            }}
          />
          {errorVisible && (
            <InputAlert theme={theme}>{t('components.auth.errorSmsCode')}</InputAlert>
          )}
        </CodeInputWrapper>
        <Button theme={theme} onClick={onSubmit}>
          {t('components.auth.resendSmsCode')}
        </Button>
      </>
    );
  };

  const renderPhoneInput = () => {
    const { phone, phoneValid, errorVisible } = phoneState;
    let icon;
    if (phone.length) {
      icon = errorVisible || !phoneValid ? 'error' : 'success';
    }
    return (
      <PhoneInput
        phone={phone}
        country={country}
        icon={icon as iconType}
        onPhoneChange={onChangePhone}
        businessInfo={businessInfo}
      >
        {!phoneValid && <InputAlert theme={theme}>{t('components.auth.errorPhone')}</InputAlert>}
      </PhoneInput>
    );
  };

  const networkID = urlManager.getNetworkId();
  const businessID = urlManager.getBusinessId();
  const { data: networkData} = useQuery<getNetworkBranchList, getNetworkBranchListVariables>(
    GET_NETWORK_BRANCH_LIST,
    {
      variables: { networkID, contractExtraId: urlManager.getContractExtraId() },
      skip: !networkID,
      fetchPolicy: 'cache-and-network',
    },
  );
  const { data: businessData} = useQuery<getBusiness, getBusinessVariables>(GET_BUSINESS, {
    variables: {
      id: businessID,
      contractID: urlManager.getContractId(),
      contractExtraId: urlManager.getContractExtraId(),
    },
    skip: !businessID,
  });

  return (
    <PageContainerStyled title={t('title.authTitle')} layoutClassName="login" contentCSS={contentCSS}>
      <LogoWithMargin
        url={urlManager.getLogoUrl({ networkID, businessID, networkData, businessData })}
      />
      <StyledHeading>{t('components.auth.phoneNumber')}</StyledHeading>
      <StyledDiv>
        {requestSmsCodeElement()}
        {smsSent ? confirmSmsCodeElement() : ''}
        <Button theme={theme} variant="link" onClick={switchAuth}>
          {t('components.auth.rememberPasswordButtonLabel')}
        </Button>
      </StyledDiv>
    </PageContainerStyled>
  );
};

export default withTranslation()(LoginSmsForm);
/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const PageContainerStyled = styled(PageContainer)({
  overflow: 'auto',
});

const StyledHeading = styled(Heading)({
  marginBottom: unit * 3,
});
const StyledLabel = styled('span')(smallText, {
  fontWeight: 500,
});
const StyledDiv = AuthForm.withComponent('div');
const CodeInputWrapper = styled('div')({
  position: 'relative',
});

const LogoWithMargin = styled(Logo)({
  marginBottom: unit * 8,
});
